import { createTheme, adaptV4Theme } from '@mui/material/styles';

export const fontFamily = [
  'Hind',
  'Roboto',
  'Arial',
  'sans-serif',
].join(',');

export const theme = createTheme(adaptV4Theme({
  palette: {
    primary: {
      main: '#e76600',
      light: '#f9ba31',
    },
    secondary: {
      main: '#353535',
      dark: '#000000'
    },
    text: {
      primary: '#353535'
    },
    background: {
      default: '#ebebeb',
      paper: '#ffffff'
    }
  },
  typography: {
    fontFamily
  }
}));