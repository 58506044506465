import { Subject, Observable } from 'rxjs';
import { filter, takeWhile, take } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';
import throttle from 'lodash.throttle'

const wsSig = {
  success: 1,
  error: 2,
  complete: 3
}

const requestStreamActions = {
  start: 'start',
  stop: 'stop'
};

export class WSService {
  messagesSubject$ = new Subject();
  wsPromise;

  requestResponse({ q, data, withCheckAlive = true }) {
    if (!this.wsPromise) {
      this.wsPromise = this._init();
    }
    withCheckAlive && this.checkAlive();

    const correlationId = uuid();
    return new Promise((resolve, reject) => {
      this.messagesSubject$.pipe(
        filter((message) => message.correlationId === correlationId),
        take(1)
      ).toPromise().then((data) => {
        switch (data.sig) {
          case wsSig.success: {
            resolve(data);
            break;
          }
          case wsSig.error: {
            reject(data);
            break;
          }
          default: {
            console.log('Unknown sig');
          }
        }
      });

      this.wsPromise.then(ws => {
        this._send(ws, {
          q,
          correlationId,
          ...data
        });
      });
    });
  }

  requestStream$({ q, data }) {
    if (!this.wsPromise) {
      this.wsPromise = this._init();
    }
    this.checkAlive();

    const correlationId = uuid();
    return new Observable((obs) => {
      const sub = this.messagesSubject$.pipe(
        filter((message) => message.correlationId === correlationId),
        takeWhile(({ sig }) => sig !== 2, true)
      ).subscribe((data) => {
        switch (data.sig) {
          case wsSig.success: {
            obs.next(data);
            break;
          }
          case wsSig.error: {
            obs.error(data);
            break;
          }
          case wsSig.complete: {
            obs.complete();
            break;
          }
          default: {
            console.log('Unknown sig');
          }
        }
      }, err => obs.error(err));
      this.wsPromise.then(ws => {
        this._send(ws, {
          q,
          correlationId,
          action: requestStreamActions.start,
          ...data
        });
      });

      return () => {
        this.wsPromise.then(ws => this._send(ws, { q, correlationId, action: requestStreamActions.stop }));
        sub.unsubscribe();
      }
    });
  }

  checkAlive = throttle(() => {
    let timeoutId;
    return Promise.race([
      new Promise((resolve) => {
        timeoutId = setTimeout(() => {
          resolve(false);
        }, 5000)
      }),
      this.requestResponse({ q: '/checkAlive', withCheckAlive: false })
        .then(() => true)
        .catch((err) => {
          console.error('checkAlive requestResponse err', err);
          return false;
        })
        .finally(() => {
          timeoutId && clearTimeout(timeoutId);
        })
    ])
      .then((isAlive) => {
        if (isAlive || !this.wsPromise) {
          return isAlive;
        }

        return this.wsPromise.then((ws) => {
          ws && ws.close();
          this.messagesSubject$.error(new Error('Lost connection'));
          this.wsPromise = undefined;
        })
          .catch((error) => {
            console.error('WS CheckAlive err after attempt to close WS', error);
          })
          .then(() => false);
      })
  }, 1000)

  _init() {
    this.messagesSubject$ = new Subject();
    return new Promise((resolve) => {
      const ws = new WebSocket((process.env.REACT_APP_API_ORIGIN || window.location.origin).replace('http', 'ws'));
      ws.onopen = () => {
        ws.onmessage = (event) => {
          this.messagesSubject$.next(JSON.parse(event.data));
        };

        ws.onerror = (err) => {
          console.error('WS err', err);
          this.messagesSubject$.error(err);
          this.wsPromise = undefined;
        }


        ws.onclose = (data) => {
          this.messagesSubject$.error(new Error('Lost connection'));
          this.wsPromise = undefined;
        }

        resolve(ws);
      }
    }).catch((err) => {
      console.error('WS Init error', err);
    });
  }

  _send(ws, data) {
    try {
      ws.send(JSON.stringify(data));
    } catch (err) {
      console.error('WS send err', err);
    }
  }
}