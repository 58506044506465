import { take, map } from 'rxjs/operators';
import {clientCallUrlPathNameLocalStorageKey} from "../utils/helpers";

export class VideoCallsService {
  constructor ({ httpDispatcherService, loadingStateService, wsService }) {
    this.loadingStateService = loadingStateService;
    this.httpDispatcherService = httpDispatcherService;
    this.wsService = wsService;
    this.videoCallToken = { callId: undefined, tokenPromise: undefined };
    this.session = { callId: undefined, startedPromise: undefined };
  }

  joinCall({ callId, pwd, identity, name }) {
    return this.wsService.requestResponse({
      q: '/videoCalls/joinCall',
      data: { callId, pwd, identity, name }
    });
  }

  async leaveCall({ callId, pwd = false, identity, withLoader = false }) {
    const eventType = 'VideoCallsService/leaveCall';
    if (withLoader) {
      this.loadingStateService.setLoadingStateForEvent({ eventType, isLoading: true });
    }

    return this.wsService.requestResponse({ q: '/videoCalls/leaveCall', data: { callId, pwd, identity } })
      .finally(() => {
        if (withLoader) {
          this.loadingStateService.setLoadingStateForEvent({ eventType, isLoading: false });
        }
      });
  }

  initiatePaymentCheckoutForCall({ callId }) {
    localStorage.setItem(clientCallUrlPathNameLocalStorageKey, `${window.location.pathname}${window.location.search}`);
    return this.wsService.requestResponse({
      q: '/videoCalls/initiatePaymentCheckoutForCall',
      data: { callId }
    }).then(({ url }) => {
      console.log('initiatePaymentCheckoutForCall', url);
      window.open(url, '_self');
    });
  }

  events$({ callId, pwd }) {
    return this.wsService.requestStream$({ q: '/videoCalls/events$', data: { callId, pwd } })
  }

  getRoomStatus({ callId, pwd }) {
    return this.events$({ callId, pwd }).pipe(
      map(({ status }) => status),
      take(1)
    ).toPromise()
      .catch((err) => {
        console.error('getRoomStatus err', err);
        throw err;
      });
  }

  getScheduledTimeOptions({ callId, pwd, withLoader = true }) {
    const loadingEventType = 'VideoCallService/getScheduledTimeOptions';

    if (withLoader) {
      this.loadingStateService.setLoadingStateForEvent({ eventType: loadingEventType, isLoading: true });
    }

    return this.httpDispatcherService.dispatch({ method: 'get', endpoint: `video-calls/${callId}/schedules?pwd=${pwd}` })
      .finally(() => {
        if (withLoader) {
          this.loadingStateService.setLoadingStateForEvent({ eventType: loadingEventType, isLoading: false });
        }
      });
  }

  confirmScheduledTime({ callId, pwd, scheduleId, withLoader = true }) {
    const loadingEventType = 'VideoCallService/confirmScheduledTime';

    if (withLoader) {
      this.loadingStateService.setLoadingStateForEvent({ eventType: loadingEventType, isLoading: true });
    }

    return this.httpDispatcherService.dispatch({
      method: 'post',
      endpoint: `video-calls/${callId}/confirm-schedule`,
      data: { scheduleId, pwd }
    })
      .finally(() => {
        if (withLoader) {
          this.loadingStateService.setLoadingStateForEvent({ eventType: loadingEventType, isLoading: false });
        }
      });
  }
}
