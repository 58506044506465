import React from 'react';
import { useHistory } from 'react-router-dom';
import propTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import Menu from '@mui/material/Menu';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Logout from '@mui/icons-material/ExitToApp';
import Settings from '@mui/icons-material/Settings';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {Fab} from "@mui/material";
import {WithConfirm} from "../common/WithConfirm";

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const getNameAbbr = (name) => {
  const nameParts = name.split(' ');
  const abbr = nameParts.length === 1 ?
    nameParts[0].slice(0, 2) :
    `${nameParts[0].slice(0, 1)}${nameParts[nameParts.length - 1].slice(0, 1)}`;
  return abbr.toUpperCase();
}

const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

const useUserStyles = makeStyles({
  userIcon: {
    marginRight: '10px',
    paddingTop: '3px',
    fontSize: '20px'
  },
  userMenuItemIcon: {
    marginTop: '-4px'
  },
  userName: {
    fontWeight: 600,
  }
});

const User = ({ name, logout, deleteAccount, areLinksAllowed }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const classes = useUserStyles();

  const history = useHistory();

  const goToSettings = () => {
    history.push('/settings/personalInfo');
    handleClose();
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Fab
        size="medium"
        color="secondary"
        aria-label="user-menu"
        aria-controls="customized-menu"
        aria-haspopup="true"
        onClick={handleClick}
        className={classes.userIcon}
      >
        {getNameAbbr(name)}
      </Fab>
      <Typography className={classes.userName} component="span">{name}</Typography>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {areLinksAllowed && (
          <StyledMenuItem onClick={goToSettings}>
            <ListItemIcon className={classes.userMenuItemIcon}>
              <Settings fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Settings" />
          </StyledMenuItem>
        )}
        <StyledMenuItem onClick={logout}>
          <ListItemIcon className={classes.userMenuItemIcon}>
            <Logout fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </StyledMenuItem>
        <WithConfirm
          description="Are you sure? All your account data will be deleted. The action can not be reverted."
          onAgree={deleteAccount}
        >
          {({ openConfirmation }) => (
            <StyledMenuItem onClick={openConfirmation} sx={{ color: 'red' }}>
              <ListItemIcon className={classes.userMenuItemIcon}>
                <DeleteForeverIcon sx={{ color: 'red' }} fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Delete Account" />
            </StyledMenuItem>
          )}
        </WithConfirm>
      </StyledMenu>
    </div>
  );
};

User.propTypes = {
  name: propTypes.string.isRequired,
  logout: propTypes.func.isRequired,
}

export default User;
