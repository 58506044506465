import axios from 'axios'

export class HttpDispatcherService {
  constructor ({ authService }) {
    this.authService = authService;
  }

  dispatch({ endpoint, url = '', method = 'get', data, headers = {}, downloadFileData = false }) {
    let isTokenRefreshed = false;
    const sendRequest = ({ token }) => {
      return axios.request({
        url: url || `${process.env.REACT_APP_API_ORIGIN || ''}/api/${endpoint}`,
        method,
        data,
        responseType: !downloadFileData ? 'json' : 'blob',
        headers: {
          authorization: `Bearer ${token}`,
          ...headers
        }
      })
        .then(({ data }) => {
          if (downloadFileData) {
            const url = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', downloadFileData.name);
            document.body.appendChild(link);
            link.click();
          }
          return data;
        })
        .catch(async (error) => {
          let errorData;
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            errorData = { ...error.response.data, status: error.response.status };
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            errorData = { message: 'No response has been received' };
          } else {
            // Something happened in setting up the request that triggered an Error
            errorData = { message: error.message };
          }

          if (errorData.status === 401 && !isTokenRefreshed) {
            await this.authService.updateUserAccessToken();
            const token = this.authService.getAccessToken();
            isTokenRefreshed = true;
            return sendRequest({ token });
          } else {
            throw errorData;
          }
        });
    }

    return sendRequest({ token: this.authService.getAccessToken() });
  }
}
