import {LoadingStateService} from "./LoadingState";
import {AuthService} from "./Auth";
import { WSService } from "./WS";
import {HttpDispatcherService} from "./HttpDispatcher";
import {PracticeAreasService} from "./PracticeAreas";
import {CasesService} from "./Cases";
import {UserService} from "./User";
import {NotificationsService} from "./Notifications";
import {VideoCallsService} from "./VideoCalls";
import {PaymentService} from "./Payment";
import {GoogleAPIService} from "./GoogleAPI";

export const bootstrapServices = () => {
  const notificationsService = new NotificationsService();
  const loadingStateService = new LoadingStateService();
  const authService = new AuthService();
  const wsService = new WSService();
  const httpDispatcherService = new HttpDispatcherService({ authService });
  const userService = new UserService({ httpDispatcherService, loadingStateService, notificationsService });
  const practiceAreasService = new PracticeAreasService({ httpDispatcherService, loadingStateService });
  const casesService = new CasesService({
    httpDispatcherService, loadingStateService, notificationsService, userService, practiceAreasService
  });
  const videoCallsService = new VideoCallsService({ httpDispatcherService, loadingStateService, wsService });
  const paymentService = new PaymentService({ httpDispatcherService, loadingStateService, notificationsService });
  // const googleApiService = new GoogleAPIService({ httpDispatcherService, loadingStateService });

  return {
    notificationsService,
    loadingStateService,
    authService,
    wsService,
    httpDispatcherService,
    practiceAreasService,
    userService,
    casesService,
    videoCallsService,
    paymentService,
    // googleApiService
  };
}