import CircularProgress from "@mui/material/CircularProgress";
import Fade from "@mui/material/Fade";
import React from "react";
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  background: {
    height: '100vh',
    width: '100vw',
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    position: 'fixed',
    zIndex: 1500,
    top: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
});

const Loader = ({ isVisible, noFade = false }) => {
  const classes = useStyles()

  return (
    <Fade in={isVisible}>
      <div className={classes.background}>
        <CircularProgress size={50} thickness={3.7} timeout={{ enter: noFade ? 0 : 500, exit: noFade ? 0 : 700 }} />
      </div>
    </Fade>
  )
};

export default Loader;