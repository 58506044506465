import { Subject } from 'rxjs';

export class NotificationsService {
  constructor () {
    this.notificationsSubject$ = new Subject();
  }

  emit(notification) {
    this.notificationsSubject$.next(notification);
  }

  listen$() {
    return this.notificationsSubject$.asObservable();
  }
}
