import React from 'react';

const Cases = React.lazy(() => import('./pages/Cases/Cases'));
const Settings = React.lazy(() => import('./pages/Settings/Settings'));
const VerifyEmail = React.lazy(() => import('./pages/VerifyEmail/VerifyEmail'));
const CaseDetails = React.lazy(() => import('./pages/CaseDetails/CaseDetails'));
const GetStarted = React.lazy(() => import('./pages/GetStarted/GetStarted'));
// const GoogleApiCallback = React.lazy(() => import('./pages/GoogleApiCallback'));
const Video = React.lazy(() => import('./pages/Video/Video'));
const ConfirmSchedule = React.lazy(() => import('./pages/ConfirmSchedule/ConfirmSchedule'));
const LeadSuccessPayment = React.lazy(() => import('./pages/LeadSuccessPayment'));

const routes = [
  { path: '/', exact: true, name: 'Cases', component: Cases },
  { path: '/verify-email', exact: true, name: 'Verify Email', component: VerifyEmail },
  { path: '/settings/:settingTab', exact: true, name: 'Settings', component: Settings },
  // { path: '/google/oauth2/callback', exact: true, name: 'Google Api Callback', component: GoogleApiCallback },
  { path: '/cases/:id', exact: true, name: 'Case Details', component: CaseDetails },
  { path: '/get-started/:stepId', name: 'Get Started', component: GetStarted },
  { path: '/video/:callId', name: 'Video', component: Video },
  { path: '/client/:callId/confirm-schedule', name: 'LeadVideoConfirmSchedule', component: ConfirmSchedule },
  { path: '/client/success-payments', name: 'Lead Success Payment', component: LeadSuccessPayment },
  { path: '/client/:callId', name: 'LeadVideo', component: Video },
];

export default routes;
