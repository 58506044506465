import {defaultCasesSort, notificationEventTypes} from "../utils/consts";
import {parseToBoolean} from "../utils/helpers";

const defaultPagination = { page: 1, perPage: 5 };

export class CasesService {
  constructor ({ httpDispatcherService, practiceAreasService, loadingStateService, notificationsService, userService }) {
    this.loadingStateService = loadingStateService;
    this.practiceAreasService = practiceAreasService;
    this.httpDispatcherService = httpDispatcherService;
    this.notificationsService = notificationsService;
    this.userService = userService;
  }

  async getCases({ withLoader = true, query = {}, sort = defaultCasesSort, pagination = defaultPagination } = {}) {
    if (pagination.page) {
      pagination.page = +pagination.page;
    } else {
      pagination.page = 1;
    }
    if (pagination.perPage) {
      pagination.perPage = +pagination.perPage;
    }
    if (query.isOwner) {
      query.isOwner = parseToBoolean(query.isOwner);
    }
    const loadingEventType = 'CasesService/getCases';

    if (withLoader) {
      this.loadingStateService.setLoadingStateForEvent({ eventType: loadingEventType, isLoading: true });
    }

    const { labelsMap: practiseAreasLabelMap } = await this.practiceAreasService.getAll();

    return this.httpDispatcherService.dispatch({
      endpoint: 'cases/search',
      method: 'post',
      data: {
        query: {
          isOwner: query.isOwner,
          practiceArea: query.practiceArea,
          city: query.city,
          zipCode: query.zipCode,
          region: query.region
        },
        sort: { [sort.field]: sort.order === 'desc' ? -1 : 1 },
        pagination: {
          page: pagination.page,
          perPage: pagination.perPage
        }
      }
    })
      .then(({ data, pagination }) => {
        return {
          data: data.map(({ practiceArea, ...caseData }) => ({
            ...caseData,
            practiceArea: practiseAreasLabelMap[practiceArea],
          })),
          pagination
        }
      })
      .catch((err) => {
        this.notificationsService.emit({
          type: notificationEventTypes.globalError,
          data: {
            title: 'Server error has happened while loading cases data',
          }
        });
        throw err;
      })
      .finally(() => {
        if (withLoader) {
          this.loadingStateService.setLoadingStateForEvent({ eventType: loadingEventType, isLoading: false });
        }
      });
  }

  async getCaseDetails({ withLoader = true, caseId } = {}) {
    const loadingEventType = 'CasesService/getCaseDetails';

    if (withLoader) {
      this.loadingStateService.setLoadingStateForEvent({ eventType: loadingEventType, isLoading: true });
    }

    const { labelsMap: practiseAreasLabelMap } = await this.practiceAreasService.getAll();
    return this.httpDispatcherService.dispatch({
      endpoint: `cases/${caseId}`,
      method: 'get',
    })
      .then(({ practiceArea, ...caseData }) => ({
        ...caseData,
        practiceArea: practiseAreasLabelMap[practiceArea]
      }))
      .catch((err) => {
        this.notificationsService.emit({
          type: notificationEventTypes.globalError,
          data: {
            title: 'Server error has happened while loading case details',
          }
        });
        throw err;
      })
      .finally(() => {
        if (withLoader) {
          this.loadingStateService.setLoadingStateForEvent({ eventType: loadingEventType, isLoading: false });
        }
      });
  }
}
