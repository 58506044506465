import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
// import * as Sentry from "@sentry/react";
// import { Integrations } from "@sentry/tracing";
import './styles/index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

// const oldProductionLink = 'https://pro-prudence.herokuapp.com';
// const newProductionLink = 'https://app.proprudence.com';

// if (window.location.origin === oldProductionLink) {
//   window.location.replace(window.location.href.replace(oldProductionLink, newProductionLink))
// } else {
//   if (process.env.REACT_APP_SENTRY_DSN_UI) {
//     Sentry.init({
//       dsn: process.env.REACT_APP_SENTRY_DSN_UI,
//       integrations: [new Integrations.BrowserTracing()],
//       tracesSampleRate: 1.0,
//     });
//   }
// }

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

serviceWorker.unregister();
