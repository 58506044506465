import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

export class LoadingStateService {
  loadingEvents = {};
  loadingState$ = new BehaviorSubject(undefined);

  setLoadingStateForEvent({ eventType, isLoading }) {
    this.loadingEvents = { ...this.loadingEvents, [eventType]: isLoading };
    this._emitLoadingStateUpdateIfNeeded();
  }

  getLoadingState$ = () => this.loadingState$.pipe(distinctUntilChanged()).asObservable();

  _emitLoadingStateUpdateIfNeeded() {
    this.loadingState$.next(
      Object.values(this.loadingEvents).some((loadingState => loadingState))
    );
  }
}
