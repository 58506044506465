import React from 'react';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    border: `2px solid ${theme.palette.primary.light}`,
    minWidth: '70%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '70vh',
    flexDirection: 'column'
  },
  iconWrapper: {
    marginBottom: '10px',
    textAlign: 'center'
  },
  title: {
    fontSize: '25px',
    fontWeight: 'bold',
  },
}));

const NotFound404 = (_) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.title}><span>404 - Page Not Found</span></div>
    </div>
  )
};

export default NotFound404;
