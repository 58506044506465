import {notificationEventTypes} from "../utils/consts";

export class PaymentService {
  constructor ({ httpDispatcherService, loadingStateService, notificationsService }) {
    this.loadingStateService = loadingStateService;
    this.notificationsService = notificationsService;
    this.httpDispatcherService = httpDispatcherService;
  }

  async saveCard({ stripe, card }) {
    const loadingEventType = 'PaymentService/saveCard';
    this.loadingStateService.setLoadingStateForEvent({ eventType: loadingEventType, isLoading: true });

    try {
      const { secret, name } = await this._createPaymentIntentForCardVerification();
      const cardPaymentRes = await stripe.confirmCardPayment(secret, {
        payment_method: {
          card,
          billing_details: { name }
        },
        setup_future_usage: 'off_session'
      });
      if (cardPaymentRes.paymentIntent) {
        await this._cancelPaymentHold({ paymentId: cardPaymentRes.paymentIntent.id });
        const updatedPaymentMethods = await this.getPaymentMethods({ withLoader: false });
        this.loadingStateService.setLoadingStateForEvent({ eventType: loadingEventType, isLoading: false });
        return updatedPaymentMethods;
      }
      if (cardPaymentRes.error) {
        console.error('Error while confirming card payment', cardPaymentRes.error);
        throw cardPaymentRes.error;
      }
    } catch (err) {
      console.log('Error while saving a card', err);
      this.loadingStateService.setLoadingStateForEvent({ eventType: loadingEventType, isLoading: false });
      throw err;
    }
  }

  async getPaymentMethods({ withLoader = true } = {}) {
    const loadingEventType = 'PaymentService/getPaymentMethods';

    if (withLoader) {
      this.loadingStateService.setLoadingStateForEvent({ eventType: loadingEventType, isLoading: true });
    }

    return this.httpDispatcherService.dispatch({
      endpoint: `payment/paymentMethods`,
      method: 'get',
    })
      .finally(() => {
        if (withLoader) {
          this.loadingStateService.setLoadingStateForEvent({ eventType: loadingEventType, isLoading: false });
        }
      });
  }

  async addSubscription({ productId }) {
    const loadingEventType = 'PaymentService/addSubscription';
    this.loadingStateService.setLoadingStateForEvent({ eventType: loadingEventType, isLoading: true });

    try {
      await this.httpDispatcherService.dispatch({
        endpoint: 'payment/subscriptions',
        method: 'post',
        data: { productId }
      });
      this.loadingStateService.setLoadingStateForEvent({ eventType: loadingEventType, isLoading: false });
    } catch (err) {
      console.log('Error while saving a card', err);
      this.loadingStateService.setLoadingStateForEvent({ eventType: loadingEventType, isLoading: false });
      throw err;
    }
  }

  async cancelSubscription({ subscriptionId }) {
    const loadingEventType = 'PaymentService/cancelSubscription';
    this.loadingStateService.setLoadingStateForEvent({ eventType: loadingEventType, isLoading: true });

    try {
      await this.httpDispatcherService.dispatch({
        endpoint: `payment/subscriptions/${subscriptionId}`,
        method: 'delete',
      });
      this.loadingStateService.setLoadingStateForEvent({ eventType: loadingEventType, isLoading: false });
      this.notificationsService.emit({
        type: notificationEventTypes.alert,
        data: {
          message: 'Subscription has been canceled',
          type: 'success',
          millisecsToShow: 5000
        }
      });
    } catch (err) {
      console.log('Error while canceling a subscription', err);
      this.loadingStateService.setLoadingStateForEvent({ eventType: loadingEventType, isLoading: false });
      throw err;
    }
  }

  async getTiers() {
    const loadingEventType = 'PaymentService/getTiers';
    this.loadingStateService.setLoadingStateForEvent({ eventType: loadingEventType, isLoading: true });

    try {
      const data = await this.httpDispatcherService.dispatch({
        endpoint: 'payment/tiers',
        method: 'get',
      });
      this.loadingStateService.setLoadingStateForEvent({ eventType: loadingEventType, isLoading: false });
      return data;
    } catch (err) {
      this.loadingStateService.setLoadingStateForEvent({ eventType: loadingEventType, isLoading: false });
      throw err;
    }
  }

  async getActiveSubscription() {
    const loadingEventType = 'PaymentService/getActiveSubscription';
    this.loadingStateService.setLoadingStateForEvent({ eventType: loadingEventType, isLoading: true });
    try {
      const data = await this.httpDispatcherService.dispatch({
        endpoint: 'payment/subscriptions/active',
        method: 'get',
      });
      this.loadingStateService.setLoadingStateForEvent({ eventType: loadingEventType, isLoading: false });
      return data;
    } catch (err) {
      this.loadingStateService.setLoadingStateForEvent({ eventType: loadingEventType, isLoading: false });
      throw err;
    }
  }

  async retryPayment() {
    const loadingEventType = 'PaymentService/retryPayment';
    this.loadingStateService.setLoadingStateForEvent({ eventType: loadingEventType, isLoading: true });
    try {
      await this.httpDispatcherService.dispatch({
        endpoint: 'payment/subscriptions/retry-payment',
        method: 'post',
      });

      let retries = 0;
      const checkIfSubscriptionBecomeActive = async () => {
        // Wait some time in order for subscription status to be updated after the successful payment
        await new Promise((resolve) => setTimeout(resolve, 2000));
        const updatedActiveSubscription = await this.getActiveSubscription();

        console.log('checkIfSubscriptionBecomeActive runs', { updatedActiveSubscription, retries });

        if (updatedActiveSubscription.status === 'active') {
          return updatedActiveSubscription;
        }

        if (retries < 4) {
          retries++;
          return checkIfSubscriptionBecomeActive();
        }

        // TODO Seems to be not realistic
        throw new Error('Subscription status is still not "active"');
      };

      const updatedSubscription = await checkIfSubscriptionBecomeActive();
      this.loadingStateService.setLoadingStateForEvent({ eventType: loadingEventType, isLoading: false });

      this.notificationsService.emit({
        type: notificationEventTypes.alert,
        data: {
          message: 'Payment has been retried successfully.',
          type: 'success',
          millisecsToShow: 5000
        }
      });

      return updatedSubscription;
    } catch (err) {

      console.log('Retry payment error', err);

      this.loadingStateService.setLoadingStateForEvent({ eventType: loadingEventType, isLoading: false });
      this.notificationsService.emit({
        type: notificationEventTypes.alert,
        data: {
          message: 'Payment retry has failed. Please check your payment settings.',
          type: 'error',
          millisecsToShow: 5000
        }
      });
    }
  }

  // async getAccount({ id }) {
  //   const loadingEventType = 'PaymentService/getAccount';
  //   this.loadingStateService.setLoadingStateForEvent({ eventType: loadingEventType, isLoading: true });
  //   try {
  //     const data = await this.httpDispatcherService.dispatch({
  //       endpoint: `payment/accounts/${id}`,
  //       method: 'get',
  //     });
  //     this.loadingStateService.setLoadingStateForEvent({ eventType: loadingEventType, isLoading: false });
  //     return data;
  //   } catch (err) {
  //     this.loadingStateService.setLoadingStateForEvent({ eventType: loadingEventType, isLoading: false });
  //     throw err;
  //   }
  // }

  async _createPaymentIntentForCardVerification({ withLoader = false } = {}) {
    const loadingEventType = 'PaymentService/createPaymentIntentForCardVerification';

    if (withLoader) {
      this.loadingStateService.setLoadingStateForEvent({ eventType: loadingEventType, isLoading: true });
    }

    return this.httpDispatcherService.dispatch({
      endpoint: `payment/cardVerification`,
      method: 'post',
    })
      .finally(() => {
        if (withLoader) {
          this.loadingStateService.setLoadingStateForEvent({ eventType: loadingEventType, isLoading: false });
        }
      });
  }

  _cancelPaymentHold({ paymentId }) {
    return this.httpDispatcherService.dispatch({
      endpoint: `payment/cancelPaymentHold`,
      method: 'post',
      data: { paymentId }
    })
  }
}
