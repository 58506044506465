import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
// import { makeStyles } from '@mui/styles';

// const useStyles = makeStyles({ text: { color: 'white' } });

export const WithConfirm = ({
                                                                  uid = 'confirmation',
                                                                  title = '',
                                                                  description = 'Are you sure?',
                                                                  onAgree = () => {},
                                                                  onDisagree = () => {},
                                                                  children,
                                                                  beforeOpenTooltip = () => {},
                                                                  textForAgree = 'Yes',
                                                                  textForDisagree = 'No',
                                                                }) => {
  // const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const openConfirmation = () => {
    beforeOpenTooltip();
    setOpen(true);
  };

  const handleClose = (e) => {
    setOpen(false);
    onDisagree(e);
  };

  const handleAgree = (e) => {
    setOpen(false);
    onAgree(e);
  };

  return (
    <>
      {children({ openConfirmation })}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby={`${uid}-title`}
        aria-describedby={`${uid}-description`}
      >
        {title && (
          <DialogTitle data-testid={`${uid}-title`} id={`${uid}-title`}>
            {title}
          </DialogTitle>
        )}
        <DialogContent>
          <DialogContentText data-testid={`${uid}-description`} id={`${uid}-description`}>
            {description}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus data-testid={`${uid}-button-no`}>
            {textForDisagree}
          </Button>
          <Button onClick={handleAgree} data-testid={`${uid}-button-yes`}>
            {textForAgree}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
