export class PracticeAreasService {
  constructor ({ httpDispatcherService, loadingStateService }) {
    this.loadingStateService = loadingStateService;
    this.httpDispatcherService = httpDispatcherService;
  }

  data = undefined;

  async getAll({ withLoader = true, withCache = true } = {}) {
    const loadingEventType = 'PracticeAreasService/getAll';

    if (withLoader && !withCache) {
      this.loadingStateService.setLoadingStateForEvent({ eventType: loadingEventType, isLoading: true });
    }

    if (!withCache || !this.data) {
      this.data = this.httpDispatcherService.dispatch({ endpoint: 'practice-areas' })
        .then((practiceAreas) => {
          return {
            list: practiceAreas,
            labelsMap: practiceAreas.reduce((res, { value, label }) => {
              return { ...res, [value]: label }
            }, {})
          }
        })
        .finally(() => {
          if (withLoader) {
            this.loadingStateService.setLoadingStateForEvent({ eventType: loadingEventType, isLoading: false });
          }
        })
    }

    return this.data;
  }
}
