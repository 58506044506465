export const notificationEventTypes = {
  globalError: 'globalError',
  alert: 'alert'
};

export const notificationAlertTypes = {
  success: 'success',
  warning: 'warning',
  info: 'info',
  error: 'error',
}

export const defaultCasesSort = { field: 'dateAdded', order: 'desc' };

export const serverErrors = {
  holdPayment: 'Error while holding a payment',
  paymentCustomerNotFound: 'Payment customer not found',
}

export const clientErrors = {
  required: 'Can not be empty',
  invalid: (label) => `${label} is invalid`
}