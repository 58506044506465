import createAuth0Client from '@auth0/auth0-spa-js';
import jwt from 'jwt-simple';

export class AuthService {
  pathToRedirectAfterAuthKeyName = 'pathToRedirectAfterAuth';
  auth0 = null;
  accessToken = null;
  permissions = {
    videoCalls: false,
  };
  isEmailVerified = false;

  async init({ history }) {
    this.auth0 = await createAuth0Client({
      domain: process.env.REACT_APP_AUTH0_DOMAIN,
      client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
      audience: 'https://pro-prudence.com',
      grant_type: 'client_credentials',
      scope: Object.keys(this.permissions).join(' '),
    });

    // LoginWithRedirected has already been provided - use its results to finally login a user
    const isAfterLoginRedirect = this._isAfterLoginWithRedirect();
    if (isAfterLoginRedirect) {
      await this.auth0.handleRedirectCallback();
    }

    const isAuth = await this.auth0.isAuthenticated();
    if (!isAuth) {
      // Save the latest page before loginWithRedirect in order to redirect back to that page once login is completed
      const { pathname, search } = window.location;
      window.localStorage.setItem(this.pathToRedirectAfterAuthKeyName, `${pathname}${search}`);
      await this._loginWithRedirect();
      return { isAuth: false };
    } else {
      const pathToReplace = window.localStorage.getItem(this.pathToRedirectAfterAuthKeyName);
      if (!!pathToReplace || isAfterLoginRedirect) {
        history.replace(pathToReplace || '/');
        pathToReplace && window.localStorage.removeItem(this.pathToRedirectAfterAuthKeyName);
      }
      await this.updateUserAccessToken();
      this.isEmailVerified = (await this.auth0.getUser()).email_verified;

      return { isAuth: true, permissions: this.getPermissions(), isEmailVerified: this.isEmailVerified };
    }
  }

  logout() {
    return this.auth0.logout({ returnTo: window.location.origin });
  }

  async updateUserAccessToken() {
    try {
      this.accessToken = await this.auth0.getTokenSilently();
      const { scope } = jwt.decode(this.accessToken, '', true);
      this.permissions = Object.keys(this.permissions).reduce(
        (res, permission) => ({ ...res, [permission]: scope.split(' ').includes(permission) }), {}
      );
    } catch (error) {
      console.error('Error while Auth getting user data', error);
      this.logout();
    }
  }

  getAccessToken() {
    return this.accessToken;
  }

  getPermissions() {
    return this.permissions;
  }

  getEmailVerificationStatus() {
    return this.isEmailVerified;
  }

  _loginWithRedirect() {
    return this.auth0.loginWithRedirect({ redirect_uri: window.location.origin });
  }

  _isAfterLoginWithRedirect() {
    const query = window.location.search;
    return query.includes('code=') && query.includes('state=')
  }
}
