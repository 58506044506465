import {notificationEventTypes} from "../utils/consts";

export const userGetStartedSteps = {
  contactData: 'contact-data',
  unverified: 'unverified',
  paymentSettings: 'payment-settings',
};

export class UserService {
  constructor ({ httpDispatcherService, loadingStateService, notificationsService }) {
    this.loadingStateService = loadingStateService;
    this.httpDispatcherService = httpDispatcherService;
    this.notificationsService = notificationsService;
    this.cachedData = undefined;
    this.endpointBase = 'me'
  }

  update({ withLoader = true, data } = {}) {
    const loadingEventType = 'UserService/update';
    if (withLoader) {
      this.loadingStateService.setLoadingStateForEvent({ eventType: loadingEventType, isLoading: true });
    }

    return this.httpDispatcherService.dispatch({ endpoint: this.endpointBase, method: 'put', data })
      .then((updatedUser) => {
        this.cachedData = updatedUser;
        return updatedUser;
      })
      .finally(() => {
        if (withLoader) {
          this.loadingStateService.setLoadingStateForEvent({ eventType: loadingEventType, isLoading: false });
        }
      });
  }

  get({ withLoader = true, useCache = true } = {}) {
    if (useCache && this.cachedData) {
      return Promise.resolve(this.cachedData);
    }

    const loadingEventType = 'UserService/get';

    if (withLoader) {
      this.loadingStateService.setLoadingStateForEvent({ eventType: loadingEventType, isLoading: true });
    }

    return this.httpDispatcherService.dispatch({ endpoint: this.endpointBase })
      .then((userData) => {
        this.cachedData = userData;
        let isNewUserStep = undefined;
        if (!userData.name || !userData.state || !userData.phone) {
          isNewUserStep = userGetStartedSteps.contactData;
        }
        return {
          ...userData,
          isNewUserStep,
        };
      })
      .finally(() => {
        if (withLoader) {
          this.loadingStateService.setLoadingStateForEvent({ eventType: loadingEventType, isLoading: false });
        }
      });
  }

  async getPaymentAccount() {
    return this.httpDispatcherService.dispatch({ endpoint: `${this.endpointBase}/payment-account`, method: 'get' });
  }

  async establishPaymentAccount({ withLoader = true } = {}) {
    const loadingEventType = 'UserService/establishPaymentAccount';

    if (withLoader) {
      this.loadingStateService.setLoadingStateForEvent({ eventType: loadingEventType, isLoading: true });
    }

    return this.httpDispatcherService.dispatch({ endpoint: `${this.endpointBase}/payment-account`, method: 'post' })
      .then((response) => {
        if (response.onboardLinkData) {
          window.location = response.onboardLinkData.url;
        }
      })
      .catch(() => {
        if (withLoader) {
          this.loadingStateService.setLoadingStateForEvent({ eventType: loadingEventType, isLoading: false });
        }
      });
  }

  triggerEmailVerify({ withLoader = true} = {}) {
    const loadingEventType = 'UserService/triggerEmailVerify';

    if (withLoader) {
      this.loadingStateService.setLoadingStateForEvent({ eventType: loadingEventType, isLoading: true });
    }

    return this.httpDispatcherService.dispatch({ method: 'post', endpoint: `${this.endpointBase}/trigger-verify-email` })
      .then(() => {
        this.notificationsService.emit({
          type: notificationEventTypes.alert,
          data: {
            message: 'New message with verification has been sent to your email address.',
            type: 'success',
            millisecsToShow: 5000
          }
        });
      })
      .catch(() => {
        this.notificationsService.emit({
          type: notificationEventTypes.alert,
          data: {
            message: 'Can not send new verification message. Please try it again later.',
            type: 'error',
            millisecsToShow: 5000
          }
        });
      })
      .finally(() => {
        if (withLoader) {
          this.loadingStateService.setLoadingStateForEvent({ eventType: loadingEventType, isLoading: false });
        }
      });
  }

  verify({ data, withLoader = true} = {}) {
    const loadingEventType = 'UserService/verify';

    if (withLoader) {
      this.loadingStateService.setLoadingStateForEvent({ eventType: loadingEventType, isLoading: true });
    }

    return this.httpDispatcherService.dispatch({ method: 'post', data, endpoint: `${this.endpointBase}/verify` })
      .catch(() => {
        return { isVerified: false };
      })
      .finally(() => {
        if (withLoader) {
          this.loadingStateService.setLoadingStateForEvent({ eventType: loadingEventType, isLoading: false });
        }
      });
  }

  deleteMe({ data, withLoader = true} = {}) {
    const loadingEventType = 'UserService/verify';

    if (withLoader) {
      this.loadingStateService.setLoadingStateForEvent({ eventType: loadingEventType, isLoading: true });
    }

    return this.httpDispatcherService.dispatch({ method: 'delete', data, endpoint: `${this.endpointBase}` });
  }

  claimCase({ data, withLoader = true} = {}) {
    const loadingEventType = 'UserService/claimCase';

    if (withLoader) {
      this.loadingStateService.setLoadingStateForEvent({ eventType: loadingEventType, isLoading: true });
    }

    return this.httpDispatcherService.dispatch({ method: 'post', data, endpoint: `${this.endpointBase}/claim-case` })
      .finally(() => {
        if (withLoader) {
          this.loadingStateService.setLoadingStateForEvent({ eventType: loadingEventType, isLoading: false });
        }
      });
  }

  getReservedSchedules({ withLoader = true } = {}) {
    const loadingEventType = 'UserService/getReservedSchedules';

    if (withLoader) {
      this.loadingStateService.setLoadingStateForEvent({ eventType: loadingEventType, isLoading: true });
    }

    return this.httpDispatcherService.dispatch({ method: 'get', endpoint: `${this.endpointBase}/reserved-schedules` })
      .finally(() => {
        if (withLoader) {
          this.loadingStateService.setLoadingStateForEvent({ eventType: loadingEventType, isLoading: false });
        }
      });
  }
}
