import { DateTime } from 'luxon';

export const getDateString = (dateInst) => {
  return `${dateInst.getMonth() + 1}/${dateInst.getDate()}/${dateInst.getFullYear()}`;
}

export const formatDate = (timestamp) => {
  const todayDateInstance = new Date();
  const todayFormatted = getDateString(todayDateInstance);
  const yesterdayDateInstance = new Date();
  yesterdayDateInstance.setDate(todayDateInstance.getDate() - 1);
  const yesterdayFormatted = getDateString(yesterdayDateInstance);
  const currentDateFormatted = getDateString(new Date(timestamp));

  switch (currentDateFormatted) {
    case todayFormatted: {
      return 'Today';
    }
    case yesterdayFormatted: {
      return 'Yesterday';
    }
    default: {
      return currentDateFormatted;
    }
  }
};

export const parseToBoolean = (val) => {
  return val === 'true' || val === true;
}

export const formatScheduleOption = ({ start, end, isInstant }) => {
  if (isInstant) {
    return 'As soon as possible';
  }
  const dateStart = new Date(start);
  const dateEnd = new Date(end);
  const currentDateTime = DateTime.now();
  return `${dateStart.toLocaleDateString()} ${dateStart.toLocaleTimeString()} - ${dateEnd.toLocaleTimeString()} (GMT${currentDateTime.toFormat('ZZ')})`;
};

export const clientCallUrlPathNameLocalStorageKey = 'proprudence-client-call-url';