import React, {useContext} from 'react';
import makeStyles from '@mui/styles/makeStyles';
import {ServicesContext} from "../../context/services";
import User from "./User";
import {useUser} from "../../context/user";
import {Link, useLocation} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    background: `linear-gradient(90deg, ${theme.palette.primary.main} 50%, ${theme.palette.primary.light} 100%)`,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px 25px',
  },
  block: {
    display: 'flex',
    alignItems: 'center',
    height: '51px'
  },
  title: {
    fontSize: '25px',
    marginLeft: '10px'
  },
}));

const Header = ({ isProfileVisible }) => {
  const classes = useStyles();
  const { user } = useUser();
  const location = useLocation();
  const { authService, userService } = useContext(ServicesContext);

  const logo = <img style={{ marginTop: '-2px' }} width={175} src="/logo-full.png" alt="ProPrudence"/>;

  const logout = () => authService.logout();

  const deleteAccount = () => {
    userService.deleteMe().then(logout);
  }

  const isEmailVerified = authService.getEmailVerificationStatus();

  const areLinksAllowed = isProfileVisible && isEmailVerified && location.pathname !== '/getStarted';
  // eslint-disable-next-line
  if (user && analytics) {
    // eslint-disable-next-line
    analytics.identify({
      email: user.email,
      name: user.name,
      practiceAreas: user.practiceAreas
    });
  }
  return (
    <header className={classes.root}>
      <div className={classes.block}>
        {areLinksAllowed ?
          <Link to={`/`}>{logo}</Link> :
          logo
        }
      </div>
      {!!user && !!user.name && isProfileVisible && (
        <div className={classes.block}>
          <User areLinksAllowed={areLinksAllowed} name={user.name} logout={logout} deleteAccount={deleteAccount} />
        </div>
      )}
    </header>
  )
};

export default Header;
