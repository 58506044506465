import React from 'react';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    border: `2px solid ${theme.palette.error.main}`,
    minWidth: '70%',
    padding: '25px 15px'
  },
  title: {
    fontSize: '25px',
    fontWeight: 'bold',
    marginBottom: '30px',
    width: '100%',
    textAlign: 'center',
    color: theme.palette.error.main
  },
  description: {
    textAlign: 'center',
    color: theme.palette.error.main,
    fontSize: '18px',
    margin: 0
  }
}));

const CriticalError = ({
                         title = 'Critical error has occurred',
                         description = 'Please reload the page and try again later.'
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.title}><span>{title}</span></div>
      <div><p className={classes.description}>{description}</p></div>
    </div>
  )
};

export default CriticalError;
