import React, { useState } from 'react';
import { SnackbarProvider } from 'notistack';
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import {theme} from "./theme";
import { ServicesContext } from './context/services'
import { UserContext } from './context/user'
import {bootstrapServices} from "./services/bootstrapServices";
import Root from "./components/Root";

const services = bootstrapServices();

const alertPosition = {
  vertical: 'bottom',
  horizontal: 'center',
};

function App() {
  const [user, setUser] = useState();

  return (
    <ServicesContext.Provider value={services}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <SnackbarProvider
            anchorOrigin={alertPosition}
            maxSnack={5}
          >
            <UserContext.Provider value={{ user, setUser }}>
              <Root />
            </UserContext.Provider>
          </SnackbarProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </ServicesContext.Provider>
  );
}

export default App;
