import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Loader from "./common/Loader";
import Layout from "./Layout";

function Root() {
  return (
    <Router>
      <React.Suspense fallback={<Loader noFade={true} isVisible={true} />}>
        <Switch>
          <Route path="/" name="Home" render={props => <Layout {...props} />} />
        </Switch>
      </React.Suspense>
    </Router>
  );
}

export default Root;
