import React, { Suspense, useContext, useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { Route, Switch } from 'react-router-dom';
import Header from "./Header/Header";
import makeStyles from '@mui/styles/makeStyles';
import {IconButton} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import {ServicesContext} from "../context/services";
import Loader from "./common/Loader";
import routes from "../routes";
import NotFound404 from "../pages/NotFound404/NotFound404";
import CriticalError from "../pages/CriticalError/CriticalError";
import { notificationEventTypes } from '../utils/consts';
import {useUser} from "../context/user";

const useStyles = makeStyles((theme) => ({
  mainContent: {
    background: theme.palette.background.default,
    minHeight: 'calc(100vh - 71px)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  closeAlertButton: {
    color: 'white'
  },
  notificationContent: {
    flexWrap: 'nowrap',
  }
}));

function Layout(props) {
  const classes = useStyles();
  const isLeadPage = window.location.pathname.startsWith(`/client/`);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { user, setUser } = useUser();
  const { notificationsService, authService, loadingStateService, userService } = useContext(ServicesContext);
  const [isLoading, setIsLoading] = useState(!isLeadPage);

  const [globalError, setGlobalError] = useState();

  const alertAction = key => (
    <IconButton
      className={classes.closeAlertButton}
      onClick={() => closeSnackbar(key)}
      aria-label="close-alert"
      size="large">
      <CloseIcon />
    </IconButton>
  );

  useEffect(() => {
    const sub = notificationsService.listen$().subscribe(({ type, data }) => {
      switch (type) {
        case notificationEventTypes.globalError: {
          setGlobalError(data);
          break;
        }
        case notificationEventTypes.alert: {
          enqueueSnackbar(data.message, {
            variant: data.type,
            action: alertAction,
            autoHideDuration: data.millisecsToShow || 5000,
            ContentProps: {
              classes: {
                root: classes.notificationContent
              }
            }
          })
          break;
        }
        default: {
          console.log('Unhandled notification', { type, data });
        }
      }
    });

    return () => sub.unsubscribe();
  }, [notificationsService]);

  useEffect(() => {
    const sub = loadingStateService.getLoadingState$().subscribe((isLoadingState) => {
      typeof isLoadingState === 'boolean' && setIsLoading(isLoadingState);
    });

    return () => sub.unsubscribe();
  }, [loadingStateService]);

  useEffect(() => {
    // Do not run Auth0 logic for a page dedicated to a lead
    if (!isLeadPage) {
      const loadingEventType = 'App/init';
      loadingStateService.setLoadingStateForEvent({ eventType: loadingEventType, isLoading: true });

      authService.init({ history: props.history })
        .then(async (userAuthData) => {
          // If !isAuth - loginWithRedirect will occur
          if (userAuthData.isAuth) {
            if (!userAuthData.isEmailVerified) {
              props.history.replace('/verify-email');
            }
            try {
              const userData = await userService.get();
              setUser(userData);

              // If a user hasn't provided his basic data - redirect to "/getStarted" route
              if (userData.isNewUserStep && userAuthData.isEmailVerified) {
                props.history.replace(`/get-started/${userData.isNewUserStep}`);
              }
              // If a user has already been "Get Started", but tries to access the page - redirect to main
              if (!userData.isNewUserStep) {
                if (props.history.location.pathname.includes('get-started')) {
                  props.history.replace(`/`);
                }
              }
            } catch (error) {
              notificationsService.emit({
                type: notificationEventTypes.globalError,
                data: {
                  description: 'Server is not available. Please try to reload the page.',
                }
              });
            }
            loadingStateService.setLoadingStateForEvent({ eventType: loadingEventType, isLoading: false });
          }
        })
        .catch((err) => {
          console.error('AuthService/init error', err);
          notificationsService.emit({
            type: notificationEventTypes.globalError,
            data: {
              description: 'Was not able to login. Please try to clear browser cookies and reload the page.',
            }
          });
          loadingStateService.setLoadingStateForEvent({ eventType: loadingEventType, isLoading: false });
        })
    }
  }, [loadingStateService, notificationsService, authService, props.history, userService, isLeadPage, setUser]);

  return (
    <>
      <Header isProfileVisible={!isLeadPage} />
      <Loader isVisible={isLoading} />
      <div className={classes.mainContent}>
        {globalError ? (
          <CriticalError title={globalError.title} description={globalError.description} />
        ) : (user || isLeadPage) && (
          <Suspense fallback={(<Loader noFade={true} isVisible={true} />)}>
            <Switch>
              {routes.map((route, idx) => {
                return route.component ? (
                  <Route
                    key={idx}
                    path={route.path}
                    exact={route.exact}
                    name={route.name}
                    render={props => (
                      <route.component {...props} />
                    )} />
                ) : (null);
              })}
              <Route
                path='/'
                exact={false}
                name="Not Found"
                render={props => (<NotFound404 {...props} />)}
              />
            </Switch>
          </Suspense>
        )}
      </div>
    </>
  );
}

export default Layout;
